import React from 'react';
import { TableActionButton, CustomAnchoredToolTip } from 'components/Table';
import { EConsultDeleteIcon2 } from '@econsult/econsult-ui/dist/assets';

export const DeleteButton = ({ onClick, isDisabled, tooltipLabel, tooltipAutomationLabel }) => (
  <TableActionButton
    isDisabled={isDisabled}
    disabled={isDisabled}
    onClick={onClick}
    data-automation="TableActionDeleteButton"
    aria-label="Delete Button"
    type="button"
  >
    <EConsultDeleteIcon2 disabled={isDisabled} />
    <CustomAnchoredToolTip
      automationLabel={tooltipAutomationLabel}
      label={tooltipLabel}
      align="bottom"
    />
  </TableActionButton>
);
