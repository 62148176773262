import React, { useEffect, useState } from 'react';
import {
  HomeLayout,
  HomeLayoutBody,
  HomeLayoutTop,
  HomeLayoutTopBar,
  PageLayoutBodyWrapper,
} from 'components/PageLayout/style';
import { TopBar } from 'components/TopBar';
import { useAppDispatch, useAppState } from 'store/configureStore';
import { STRINGS } from 'config';
import { apiGetAuthenticationCheck } from 'store/action/auth';
import { ErrorBoundary } from 'react-error-boundary';
import { reportError } from 'utils/datadog';
import { initiateIntercom, shutDownIntercomSession } from 'utils/intercom';
import CompactNavBar from 'components/CompactNavBar';
import { keyboardShortcutsKeyMap } from 'utils/keyboardHotkeys';
import { GlobalHotKeys } from 'react-hotkeys';
import { Outlet } from 'react-router-dom';
import { AppErrorFallback } from 'components/UserManagement/DeniedUserAction';
import { DebugFooter } from 'components/DebugFooter';
import { FadeInFadeOut, ToastContainer, ToastContainerWrapper } from 'components/Toast/style';

const {
  ENVIRONMENT: { IS_DEV_OR_TEST_ENV },
} = STRINGS;

export const PageLayout = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isNavBarExpanded, setIsNavBarExpanded] = useState(true);
  const state = useAppState();
  const dispatch = useAppDispatch();

  const { app: { selectedPractice }, userManagement: { usersInActivePracticeList } } = state;

  useEffect(() => {
    apiGetAuthenticationCheck(dispatch)
      .then(authenticationSuccessful => {
        if (authenticationSuccessful) {
          setIsAuthenticated(true);
        }
        shutDownIntercomSession();
        initiateIntercom();
      });
  }, [dispatch]);

  const keyboardShortcutHandlers = {
    TOGGLE_NAVBAR: () => setIsNavBarExpanded(lastVal => !lastVal),
  };

  return isAuthenticated && (
    <HomeLayout column data-automation="PageLayoutWrapper">
      <GlobalHotKeys keyMap={keyboardShortcutsKeyMap} handlers={keyboardShortcutHandlers} />

      <HomeLayoutTop row>
        <HomeLayoutTopBar>
          <TopBar shouldSkipToUserList={usersInActivePracticeList.length} />
        </HomeLayoutTopBar>
      </HomeLayoutTop>

      <HomeLayoutBody row>
        <CompactNavBar
          isExpanded={isNavBarExpanded}
          setIsExpanded={setIsNavBarExpanded}
        />

        <HomeLayoutBody>
          <ErrorBoundary
            FallbackComponent={() => <AppErrorFallback />}
            onError={(error, info) => reportError(error, info)}
            resetKeys={[selectedPractice]}
          >
            <PageLayoutBodyWrapper>
              <Outlet />
            </PageLayoutBodyWrapper>
          </ErrorBoundary>
        </HomeLayoutBody>
      </HomeLayoutBody>

      <ToastContainerWrapper>
        <ToastContainer
          position="bottom-left"
          closeOnClick={false}
          closeButton={false}
          pauseOnFocusLoss={false}
          autoClose={3000}
          theme="dark"
          transition={FadeInFadeOut}
          hideProgressBar
          pauseOnHover
          newestOnTop
        />
      </ToastContainerWrapper>

      <DebugFooter showDebug={IS_DEV_OR_TEST_ENV} state={state} isAuthenticating={!isAuthenticated} />
    </HomeLayout>
  );
};
