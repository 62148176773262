import { OpenNewIcon } from '@econsult/econsult-ui/dist/assets';
import styled from 'styled-components';

export const StyledExternalLink = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer noopener',
})`
  font-weight: 600;

  &,
  &:visited {
    color: ${({ theme }) => theme.colorActionPrimaryDefault};
  }
  
  &:hover {
    color: ${({ theme }) => theme.colorActionPrimaryHover};
  }
  
  &:active {
    color: ${({ theme }) => theme.colorActionPrimaryActive};
  }
`;

export const ExternalLinkIcon = styled(OpenNewIcon)`
  width: 0.75em;
  height: 0.75em;
  margin: 0 0 0 0.225em;
  box-sizing: border-box;
`;
