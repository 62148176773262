import React from 'react';
import {
  ActionButtonsContainer,
  StyledDataCell,
  StyledTableRow,
} from 'components/Table';
import { DeleteButton } from 'components/Table/deleteButton';
import { Tick } from '@econsult/econsult-ui/dist/components/TopBar/TopBarStyles';

export const UserTableRow = ({ row, onDeleteUser, selectedPracticeHasAdminRights }) => {
  const deleteButtonDisabled = !selectedPracticeHasAdminRights;
  return (
    <StyledTableRow data-automation="TableDataRow">
      <StyledDataCell
        scope="row"
        fontSize={14}
        $emphasis
        data-automation="TableDataCellName"
      >
        {(!row.name || row.name.includes('@')) ? 'Not set' : row.name.trim()}
      </StyledDataCell>
      <StyledDataCell
        scope="row"
        fontSize={14}
        data-automation="TableDataCellEmail"
      >
        {row.email}
      </StyledDataCell>
      <StyledDataCell
        scope="row"
        fontSize={14}
        data-automation="TableDataCellAdminRights"
        aria-label={row.hasAdminRights?.toString()}
      >
        {
          row.hasAdminRights
            ? <Tick automationName="UserAdminRightsTickIcon" />
            : <span data-automation="UserAdminRightsDash">-</span>
        }
      </StyledDataCell>
      <StyledDataCell>
        <ActionButtonsContainer data-automation="TableActionButtonsContainer">
          <DeleteButton
            onClick={() => onDeleteUser(row.email)}
            isDisabled={deleteButtonDisabled}
            tooltipAutomationLabel="deleteUserTooltip"
            tooltipLabel={deleteButtonDisabled ? 'Contact your practice admin' : 'Delete User'}
          />
        </ActionButtonsContainer>
      </StyledDataCell>
    </StyledTableRow>
  );
};
