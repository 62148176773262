import styled from 'styled-components';

export const DebuggingFooter = styled.div`
  && {
    position: relative;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d9f2fc;
  color: #0c0354;
  h3 {
    margin: 2px;
    font-size: 12px;
  }
 p {
    margin: 0;
   font-size: 10px;
  }
`;
