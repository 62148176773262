import { DoneIcon, ErrorIcon as ErrorIconSvg } from '@econsult/econsult-ui/dist/assets';
import { ToastContainer as UnstyledToastContainer, FadeInFadeOut } from '@econsult/econsult-ui/dist/components/Toast';
import { Div } from '@econsult/econsult-ui/dist/components/shared/div';
import styled from 'styled-components';

export const SuccessTick = styled(DoneIcon)`
  height: 18px;
  width: 18px;
  margin-right: 8px;
  color: ${({ theme }) => theme.colorBrightWhite};
`;

export const ErrorIcon = styled(SuccessTick).attrs({ as: ErrorIconSvg })``;

export const ToastContainer = styled(UnstyledToastContainer)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

export const ToastContainerWrapper = styled.div`
  && .Toastify__toast--default,
  && .Toastify__toast--error {
    border-radius: 6px;
    background: ${({ theme }) => theme.colorExpressionExpression4};
  }

  && .Toastify__toast--error {
    background: ${({ theme }) => theme.colorStatusError};
  }
  
  && .Toastify__toast-container--bottom-left {
    left: 1rem;
    bottom: 1rem;
    padding-bottom: 0;
  }

  && .Toastify__toast-container--bottom-center {
    bottom: 3rem;
    padding-bottom: 0;
  }
`;

export const ToastMessage = styled(Div)`
  align-items: center;
`;

export { FadeInFadeOut };
