import React from 'react';
import { TopBarUserActions } from 'components/TopBar/TopBarUserActions';
import PracticeDropDown from 'components/TopBar/PracticeDropDown';
import { SkipToMainContent, StyledTopBarContainer, StyledeCLogo, TitleText, TopBarTitleAndPracticeMenuContainer } from 'components/TopBar/style';

export function TopBar({ shouldSkipToUserList }) {
  return (
    <StyledTopBarContainer shown data-automation="TopBar">
      <SkipToMainContent
        href={shouldSkipToUserList ? '#SkipToUserListMainContent' : '#SkipToDefaultMainContent'}
        onclick="event.preventDefault()"
        id="skipToLoginMainContent"
      >
        Skip To Main Content
      </SkipToMainContent>
      <TopBarTitleAndPracticeMenuContainer>
        <StyledeCLogo />
        <TitleText id="SkipToDefaultMainContent">Settings</TitleText>
        <PracticeDropDown />
      </TopBarTitleAndPracticeMenuContainer>
      <TopBarUserActions />
    </StyledTopBarContainer>
  );
}
