import React from 'react';
import {
  Select as Menu,
  useSelect as useMenu,
} from '@econsult/econsult-ui/dist/components/Select';
import _map from 'lodash/map';
import {
  Caret,
  PracticeMenuItems,
  DropdownPointer,
  SelectGroupItem,
  PracticeMenuButton,
  HorizontalDivider,
  BlankMenuSpacer,
  NotAssociatedMessage,
  EHubLogo,
  PracticeLogo,
} from '@econsult/econsult-ui/dist/components/TopBar/TopBarStyles';
import {
  PracticeListItem,
  EHubListItem,
} from '@econsult/econsult-ui/dist/components/TopBar';
import { useAppDispatch, useAppState } from 'store/configureStore';
import { setPractice } from 'store/action/app';

const PracticeOrHubLogo = ({ color, isHub }) => (isHub ? <EHubLogo color={color} /> : <PracticeLogo color={color} />);

const PracticeDropDown = () => {
  const dispatch = useAppDispatch();
  const { app: { selectedPractice, user: { practices, ehubs } } } = useAppState();

  const { toggleSelect: togglePracticeSelect, selectContext: practiceSelectContext, isOptionsListOpen: isPracticeSelectOpen } = useMenu();
  return (
    <Menu closeOnClickOutside selectContext={practiceSelectContext} automationName="TopBarPracticeSelectDropdown">
      {selectedPractice?.prettyName && (
      <PracticeMenuButton onClick={togglePracticeSelect} isActive={isPracticeSelectOpen}>
        <PracticeOrHubLogo isHub={selectedPractice?.practices} color={selectedPractice.color} />
        {selectedPractice?.prettyName}
        <Caret />
      </PracticeMenuButton>
      )}
      {isPracticeSelectOpen && <DropdownPointer />}
      <PracticeMenuItems automationName="PracticeMenuItemsContainer">
        <SelectGroupItem typography="ParagraphTinyHeavy" data-automation="PracticesSectionHeader">Practices</SelectGroupItem>
        {(practices && practices.length > 0) ? (
          _map(practices, (practice, index) => (
            <PracticeListItem
              key={`practiceListItem-${index}`}
              item={practice}
              index={index}
              onClick={() => (practice?.id !== selectedPractice?.id ? setPractice(dispatch, practice, false) : null)}
              isSelected={practice?.id === selectedPractice?.id}
              iconColor={practice?.color}
            />
          ))
        ) : <NotAssociatedMessage>You are not associated to a practice</NotAssociatedMessage>}
        <BlankMenuSpacer />
        <HorizontalDivider />
        <SelectGroupItem typography="ParagraphTinyHeavy" data-automation="EhubSectionHeader">eHubs</SelectGroupItem>
        {(ehubs && ehubs.length > 0) ? (
          _map(ehubs, (eHub, index) => (
            <EHubListItem
              key={`eHubListItem-${index}`}
              eHub={eHub}
              index={index}
              onClick={() => (eHub?.id !== selectedPractice?.id ? setPractice(dispatch, eHub, true) : null)}
              isSelected={eHub?.id === selectedPractice?.id}
            />
          ))
        ) : <NotAssociatedMessage>You are not associated to an eHub</NotAssociatedMessage>}
        <BlankMenuSpacer />
      </PracticeMenuItems>
    </Menu>
  );
};

export default PracticeDropDown;
