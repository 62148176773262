import React, { useMemo } from 'react';
import { useAppDispatch, useAppState } from 'store/configureStore';
import { STRINGS } from 'config';
import { apiPostAdminAvailability, updateAdminAvailability } from 'store/action/demandManagement';
import { SaveButton } from 'components/AdminAvailability/style';
import { Radio } from 'components/Radio';
import { RadioGroup } from 'components/Radio/style';
import { InlineLink, ParagraphXSmallRegular, ParagraphXSmallHeavy } from 'components/Typography';
import { CustomAnchoredToolTip } from 'components/Table';
import { Form, Legend } from 'components/Form';

const {
  FEATURE_VALUES: { ADMIN_AVAILABILITY },
  REQUEST_STATUS: { PENDING },
} = STRINGS;

export const AdminAvailability = () => {
  const dispatch = useAppDispatch();
  const {
    app: {
      selectedPractice,
      selectedPracticeIsEhub,
      selectedPracticeHasAdminRights,
    },
    demandManagement: {
      adminAvailability,
      updateAdminAvailabilityRequestStatus,
      demandManagementEnabled,
    },
  } = useAppState();

  const isSaveDisabled = useMemo(() => (
    !selectedPracticeHasAdminRights
    || selectedPracticeIsEhub
    || updateAdminAvailabilityRequestStatus === PENDING
    || !demandManagementEnabled
  ), [selectedPracticeHasAdminRights, selectedPracticeIsEhub, updateAdminAvailabilityRequestStatus, demandManagementEnabled]);

  const isValueValidForFeature = useMemo(() => Object.keys(ADMIN_AVAILABILITY).includes(adminAvailability?.id), [adminAvailability]);

  const onChangeOpeningHours = event => {
    dispatch(updateAdminAvailability(event.target.value));
  };

  const onSaveOpeningHours = () => {
    apiPostAdminAvailability(selectedPractice.id, adminAvailability?.id)(dispatch);
  };

  return (
    <>
      <ParagraphXSmallHeavy as="h3">Administrative requests opening hours</ParagraphXSmallHeavy>
      <Form action="" onChange={onChangeOpeningHours} onSubmit={event => event.preventDefault()}>
        <RadioGroup>
          <div>
            <Legend>
              This choice lets your practice keep administrative requests available to patients
              even if our clinical pathways are closed. Even though we direct patients to other
              services and provide clear messages, there&apos;s still a chance a patient might
              send a clinical request through an administrative channel.
            </Legend>
            {Object.values(ADMIN_AVAILABILITY).map(({ id, label }) => (
              <Radio
                key={`${selectedPractice.id}--${id}`}
                id={id}
                fieldName="adminAvailability"
                value={id}
                isChecked={id === adminAvailability?.id}
                defaultChecked={id === adminAvailability?.id && isValueValidForFeature}
                onChange={onChangeOpeningHours}
                label={label}
              />
            ))}
          </div>
        </RadioGroup>
        <ParagraphXSmallRegular>
          Unless you&apos;ve changed them before, your practice opening hours will be
          set as 8am to 6:30pm, Monday to Friday. If you have any questions, please
          contact us through Live Chat or email at&nbsp;
          <InlineLink href="mailto:practice.support@econsult.health">
            practice.support@econsult.health
          </InlineLink>.
        </ParagraphXSmallRegular>
        <SaveButton onClick={onSaveOpeningHours} disabled={isSaveDisabled} type="submit" data-automation="SaveDemandManagementButton">
          Save changes
          {!selectedPracticeHasAdminRights && (
            <CustomAnchoredToolTip automationLabel="SaveDemandManagementDisabledTooltip" label="Contact your practice admin" align="top" />
          )}
        </SaveButton>
      </Form>
    </>
  );
};
