import { Paragraph } from '@econsult/econsult-ui/dist/components/shared/typography';
import { UnableToManageStateSvg } from 'assets/svg';
import styled from 'styled-components';
import { Div } from 'components/Div';
import theme from 'styles/theme';

export const DeniedActionContainer = styled(Div)`
  align-items: center;
  justify-content: flex-start;
  padding-top: 152px;
  height: 100%;
  width: 20%;
  text-align: center;
  color: ${theme.colorPrimaryPrimary100};
`;

export const DeniedText = styled(Paragraph).attrs({ typography: 'ParagraphSmallRegular' })``;

export const UnableToManageStateImage = styled(UnableToManageStateSvg)``;
