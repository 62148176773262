import React from 'react';
import { Notification } from '@econsult/econsult-ui/dist/components/Notification';
import {
  ModalConfirmButton,
  ModalCancelButton,
  ModalActionsContainer,
  ModalContents,
} from 'components/Modal';
import { FormInput } from 'components/FormInput';

const validation = 'Sorry this user already exists. Please contact eConsult via Live Chat to add this user to your practice.';

export const AddUserModalContent = ({
  onClose,
  fullName,
  emailAddress,
  fullNameValidationError,
  emailValidationError,
  onChangeFullName,
  onChangeEmailAddress,
  handleSubmitAddUser,
  userCreationError,
  shouldBlockUserActions,
}) => (
  <ModalContents>
    {userCreationError && <Notification state="error" textContent={validation} variant="filled" automationName="ErrorContainer" />}
    <FormInput
      id="nameTextInput"
      label="Full name"
      maxLength={300}
      type="text"
      value={fullName || ''}
      onChange={onChangeFullName}
      showError={fullNameValidationError}
      message={fullNameValidationError && fullNameValidationError}
      autoFocus={false}
      automationName="FullNameInput"
      disabled={shouldBlockUserActions}
    />
    <FormInput
      id="emailAddressTextInput"
      label="Email address"
      maxLength={50}
      type="text"
      value={emailAddress || ''}
      onChange={onChangeEmailAddress}
      showError={emailValidationError}
      message={emailValidationError && emailValidationError}
      autoFocus={false}
      automationName="EmailAddressInput"
      disabled={shouldBlockUserActions}
    />
    <ModalActionsContainer>
      <ModalCancelButton
        disabled={shouldBlockUserActions}
        onClick={onClose}
        data-automation="CancelAddUserButton"
        type="button"
      >Cancel
      </ModalCancelButton>
      <ModalConfirmButton
        disabled={shouldBlockUserActions}
        onClick={handleSubmitAddUser}
        data-automation="AddUserButton"
        type="button"
      >Add user
      </ModalConfirmButton>
    </ModalActionsContainer>
  </ModalContents>
);
