import React from 'react';
import { useAppState } from 'store/configureStore';
import { UserTable } from 'components/UserTable';
import { DeniedUserAction } from 'components/UserManagement/DeniedUserAction';

export const UserManagement = () => {
  const { app: { selectedPracticeIsEhub } } = useAppState();

  return selectedPracticeIsEhub ? <DeniedUserAction /> : <UserTable />;
};
