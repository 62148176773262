import { ErrorIcon, SuccessTick, ToastMessage } from 'components/Toast/style';
import React from 'react';

export const SuccessToast = ({ message }) => (
  <ToastMessage data-automation="success-toast">
    <SuccessTick />
    {message}
  </ToastMessage>
);

export const ErrorToast = ({ message }) => (
  <ToastMessage data-automation="error-toast">
    <ErrorIcon />
    {message}
  </ToastMessage>
);
