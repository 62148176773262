import { STRINGS } from 'config';

const { APP_ID, ENABLED } = STRINGS.INTERCOM;

const ifIntercomIsEnabled = func => {
  if (!ENABLED) {
    return () => null;
  }
  return func;
};

export const initiateIntercom = ifIntercomIsEnabled(() => (
  window.Intercom && window.Intercom('boot', {
    app_id: APP_ID,
  })
));

export const setupIntercom = ifIntercomIsEnabled(() => {
  (function () {
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      const d = document;
      const i = function (...args) {
        i.c(args);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = function () {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${APP_ID}`;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  }());

  window.intercomSettings = {
    app_id: APP_ID,
  };
  return initiateIntercom();
});

export const shutDownIntercomSession = ifIntercomIsEnabled(() => window.Intercom && window.Intercom('shutdown'));

export const updateIntercom = ifIntercomIsEnabled(userData => window.Intercom && window.Intercom('update', userData));
