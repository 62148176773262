import styled from 'styled-components';

export const Div = styled.div`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
`;

export const ColumnDiv = styled(Div)`
  flex-direction: column;
`;

export const ScrollableContainerOuter = styled(ColumnDiv)`
  overflow-x: hidden;
  position: relative;
  ${({ theme }) => theme.hiddenScrollbarStyles};
`;

export const ScrollableContainerOuterWithVerticalScrollbar = styled(ColumnDiv)`
  overflow-x: hidden;
  position: relative;
  overflow-y: auto;
`;

export const ScrollableContainerInner = styled(ColumnDiv)`
  position: absolute;
  width: 100%;
`;
