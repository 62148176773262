import { STRINGS } from 'config';
import _filter from 'lodash/filter';

const {
  APP: { SET_PRACTICE, RESET_ALL_STATE },
  USER_MANAGEMENT: {
    API_DELETE_USER_ERROR,
    API_DELETE_USER_SUCCESS,
    API_POST_ADD_USER_SUCCESS,
    API_POST_ADD_USER_ERROR,
    API_GET_USERS_SUCCESS,
    RESET_USER_CREATION_STATUS,
    RESET_USER_DELETE_STATUS,
  },
} = STRINGS.REDUCERS;

export const initialUserManagementState = {
  usersInActivePracticeList: [],
  userCreationStatus: null,
  userDeleteStatus: null,
};

export const userManagementReducer = (state, action) => {
  switch (action.type) {
    case SET_PRACTICE: {
      return {
        ...state,
        usersInActivePracticeList: [],
      };
    }
    case API_DELETE_USER_ERROR: {
      return { ...state, userDeleteStatus: action.payload };
    }
    case API_DELETE_USER_SUCCESS: {
      const usersInActivePracticeWithoutDeletedUser = _filter(
        state.usersInActivePracticeList.users,
        user => user.email !== action.payload,
      );
      return {
        ...state,
        usersInActivePracticeList: {
          ...state.usersInActivePracticeList,
          total: state.usersInActivePracticeList.total - 1,
          length: state.usersInActivePracticeList.length - 1,
          users: usersInActivePracticeWithoutDeletedUser,
        },
      };
    }
    case API_POST_ADD_USER_SUCCESS: {
      return {
        ...state,
        usersInActivePracticeList:
        {
          ...state.usersInActivePracticeList,
          total: state.usersInActivePracticeList.total + 1,
          length: state.usersInActivePracticeList.length + 1,
          users: [
            action.payload,
            ...state.usersInActivePracticeList.users,
          ].sort((a, b) => ((a.email > b.email) ? 1 : -1)),
        },
      };
    }
    case API_POST_ADD_USER_ERROR: {
      return {
        ...state,
        userCreationStatus: action.payload,
      };
    }
    case API_GET_USERS_SUCCESS: {
      return { ...state, usersInActivePracticeList: action.payload };
    }
    case RESET_USER_CREATION_STATUS: {
      return {
        ...state,
        userCreationStatus: null,
      };
    }
    case RESET_USER_DELETE_STATUS: {
      return {
        ...state,
        userDeleteStatus: initialUserManagementState.userDeleteStatus,
      };
    }
    case RESET_ALL_STATE: {
      return {
        ...initialUserManagementState,
      };
    }
    default: {
      return state;
    }
  }
};
