import _map from 'lodash/map';

export const removeCookiesWithStringPrefix = (prefix, domain, sameSite) => {
  const cookies = document.cookie.split(';');
  _map(cookies, cookieUntrimmed => {
    const cookie = cookieUntrimmed.trim();
    if (cookie.startsWith(prefix)) {
      const [cookieName] = cookie.split('=');
      const cookieOptions = [
        domain ? `domain=${domain}` : '',
        sameSite ? `sameSite=${sameSite}` : '',
        'expires=Thu, 01 Jan 1970 00:00:01 GMT',
      ].filter(Boolean).join(';');
      document.cookie = `${cookieName}=; path=/; ${cookieOptions};`;
    }
  });
};

export const loadCookieFirst = () => {
  const w = window;
  const d = document;
  const l = function () {
    const s = d.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://consent.cookiefirst.com/sites/settings.econsult.health-4dcc3913-9df6-4f80-9416-f452b799ec0a/consent.js';
    const x = d.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  };
  if (document.readyState === 'complete') {
    l();
  } else if (w.attachEvent) {
    w.attachEvent('onload', l);
  } else {
    w.addEventListener('load', l, false);
  }
};
