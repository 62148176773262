import React from 'react';
import { DeniedActionContainer, DeniedText, UnableToManageStateImage } from 'components/UserManagement/style';

export const DeniedUserAction = () => (
  <DeniedActionContainer column data-automation="deniedActionContainer">
    <UnableToManageStateImage dataAutomation="unableToManageEhubImage" alt="unableToManageEhubImage" />
    <DeniedText data-automation="unableToManageEhubText" role="heading" aria-level="1">
      Unfortunately you are currently unable to manage eHub users. Please contact eConsult via Live Chat to manage users for this eHub.
    </DeniedText>
  </DeniedActionContainer>
);

export const AppErrorFallback = () => (
  <DeniedActionContainer column data-automation="AppErrorFallback">
    <UnableToManageStateImage />
    <DeniedText role="heading" aria-level="1">
      Unfortunately eConsult Settings is currently unavailable. Please try again later or contact eConsult at practice.support@econsult.health.
    </DeniedText>
  </DeniedActionContainer>
);
