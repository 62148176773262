import React from 'react';
import {
  ModalTitleAndClose,
  Modal,
  ModalTitle,
  ModalConfirmButton,
  ModalCancelButton,
  ModalActionsContainer,
  ModalContents,
  ModalTextSmall,
} from 'components/Modal';

export const ConfirmActionModal = ({ isOpen, onCancel, onConfirm, actionName, confirmActionModalCopy, confirmActionButtonText }) => (
  <Modal
    isOpen={isOpen}
    onClose={onCancel}
    modalContainerDataAutomationName={`${actionName}Modal`}
    role="dialog"
  >
    <ModalTitleAndClose style={{ borderBottom: 'none' }}>
      <ModalTitle data-automation="ModalTitleConfirm">Confirm</ModalTitle>
    </ModalTitleAndClose>
    <ModalContents style={{ textAlign: 'center' }}>
      <ModalTextSmall data-automation={`${actionName}ModalBodyText`}>{confirmActionModalCopy}</ModalTextSmall>
      <ModalActionsContainer>
        <ModalCancelButton onClick={onCancel} data-automation={`Cancel${actionName}Button`}>Cancel</ModalCancelButton>
        <ModalConfirmButton
          onClick={onConfirm}
          data-automation={`${actionName}ConfirmButton`}
          type="button"
        >
          {confirmActionButtonText}
        </ModalConfirmButton>
      </ModalActionsContainer>
    </ModalContents>
  </Modal>
);
