import styled from 'styled-components';
import TableCell from '@mui/material/TableCell';

export const HeaderCell = styled(TableCell).attrs({ typography: 'ParagraphTinyHeavy' })`
  && {
    color: ${({ theme }) => theme.colorNeutralNeutral1};
    ${({ theme }) => theme.fontStyles};
    padding: 0;
    border: 0;
    letter-spacing: normal;
  }
`;

export const DataCell = styled(TableCell).attrs({ typography: 'ParagraphTinyRegular' })`
  && {
    color: ${({ theme }) => theme.colorPrimaryPrimary70};
    ${({ theme }) => theme.fontStyles};
    padding: 12px 0;
    letter-spacing: normal;
  }
`;
