export const FEATURE_VALUES = {
  ADMIN_AVAILABILITY: {
    OPENING_HOURS: {
      id: 'OPENING_HOURS',
      label: 'Open in eConsult opening hours',
    },
    PHYSICAL_HOURS: {
      id: 'PHYSICAL_HOURS',
      label: 'Open in practice opening hours',
    },
    UNRESTRICTED: {
      id: 'UNRESTRICTED',
      label: 'Always open',
    },
  },
};
