import { eCLogo } from 'assets';
import { Paragraph } from '@econsult/econsult-ui/dist/components/shared/typography';
import styled from 'styled-components';
import { Div } from 'components/Div';
import { TopBarContainer } from '@econsult/econsult-ui/dist/components/TopBar/TopBarStyles';

export const TopBarTitleAndPracticeMenuContainer = styled(Div)`
  justify-content: center;
  align-items: center;
`;

export const StyledeCLogo = styled(eCLogo)`
  width: 1.6875rem;
  height: 1.6875rem; 
  padding: 0 0.75rem 0 1.5rem;
`;

export const TitleText = styled(Paragraph).attrs({ typography: 'ParagraphBaseHeavy' })`
  margin: 0;
  color: ${({ theme }) => theme.colorPrimaryPrimary30};
  padding: 0 3rem 0 0;
`;

export const StyledTopBarContainer = styled(TopBarContainer)`
  && {
    position: relative;
  }
`;

export const SkipToMainContent = styled.a`
  padding: 8px;
  position: absolute;
  top: -100px;
  left: 45%;
  color: ${({ theme }) => theme.colorNeutralBlack};
  background: ${({ theme }) => theme.colorNeutralBrightWhite};
  transition: top 1s ease-out;
  z-index: 4;
  :focus {
    position: absolute;
    left: 45%;
    top: 8px;
    transition: top 0.2s ease-in;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 1px;
  }
`;
