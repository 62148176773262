import { STRINGS } from 'config';

const {
  AUTH: {
    API_CHECK_AUTHENTICATION_SUCCESS,
    CHECK_AUTHENTICATION_ERROR,
  },
  APP: { RESET_ALL_STATE },
} = STRINGS.REDUCERS;

export const initialAuthState = {
  isLoggedIn: false,
};

export const authReducer = (state, action) => {
  switch (action.type) {
    case RESET_ALL_STATE: {
      return {
        ...initialAuthState,
      };
    }
    case API_CHECK_AUTHENTICATION_SUCCESS: {
      return { ...state, isLoggedIn: true };
    }
    case CHECK_AUTHENTICATION_ERROR: {
      return { ...state, isLoggedIn: false };
    }
    default: {
      return state;
    }
  }
};
