import { datadogLogs } from '@datadog/browser-logs';
import { STRINGS } from 'config';

export const initialiseDatadog = () => {
  if (STRINGS.ENVIRONMENT.IS_LOCAL) {
    return null;
  }
  datadogLogs.init({
    clientToken: STRINGS.DATADOG.ID,
    site: 'datadoghq.eu',
    forwardErrorsToLogs: false,
    env: process.env.REACT_APP_ENVIRONMENT,
    service: `settings-frontend:${process.env.REACT_APP_ENVIRONMENT}`,
  });
  datadogLogs.logger.setLevel('error');
  datadogLogs.setGlobalContextProperty('settings_frontend_version', process.env.REACT_APP_VERSION);
  return null;
};

export const reportError = (error, info) => datadogLogs.logger.error('UNCAUGHT ERROR', {
  errorInfo: info,
  error: error.toString(),
  env: process.env.REACT_APP_ENVIRONMENT,
});
