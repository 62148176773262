import styled from 'styled-components';
import { Div } from 'components/Div';

export const PageLayoutBodyWrapper = styled.div`
  flex: 1;
  display: flex; 
  align-content: center;
  justify-content: center; 
  margin-top: 32px;
`;

export const HomeLayout = styled(Div)`
  outline: none;
  flex: 1 1 auto;
`;

export const HomeLayoutTopBar = styled(Div)`
  flex: 1;
`;

export const HomeLayoutBody = styled(Div)`
  flex: 1 1 auto;
  position: relative;
  outline: none;
`;

export const HomeLayoutTop = styled(Div)`
  position: relative;
  outline: none;
  flex: 0;
  width: 100%;
`;
