import styled from 'styled-components';
import { Div, ColumnDiv } from '@econsult/econsult-ui/dist/components/shared/div';
import { Button } from '@econsult/econsult-ui/dist/components/Button';
import theme from 'styles/theme';

export const HeaderColumn = styled(ColumnDiv)``;
export const HeaderRow = styled(Div)``;

export const HeaderColumnRight = styled(ColumnDiv)`
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;
`;

export const AddUserButtonContainer = styled(ColumnDiv)`
    width: 104px;
    height: 32px;
`;

export const AddUserModalButton = styled(Button).attrs({
  size: 'small',
  fillStyle: 'ghost',
})`
    border-width: 1px;
    padding: 8px;
    width: 104px;
    height: 32px;
    :disabled {
    color: ${theme.colorPrimaryPrimary50} !important;
    }
`;
