import React, { useEffect, useRef } from 'react';
import { RadioInput, RadioLabel, RadioWrapper } from 'components/Radio/style';

export const Radio = props => {
  const { id, label, fieldName, value, isChecked, onChange, defaultChecked } = props;

  const ref = useRef();

  useEffect(() => {
    if (defaultChecked && isChecked && ref.current) {
      ref.current.click();
    }
  }, [defaultChecked, isChecked]);

  return (
    <RadioWrapper htmlFor={`radioInput--${id}`} data-automation={`Radio--${label}`}>
      <RadioInput
        ref={ref}
        id={`radioInput--${id}`}
        name={fieldName}
        value={value}
        onChange={onChange}
        defaultChecked={defaultChecked}
        data-automation={`RadioInput--${fieldName}--${id}`}
      />

      <RadioLabel>{label}</RadioLabel>
    </RadioWrapper>
  );
};
