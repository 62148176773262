import React from 'react';
import { StyledInput, FormItemWrapper } from 'components/FormInput/style';

export const FormInput = props => {
  const {
    id,
    label,
    placeholder,
    maxLength,
    value,
    onChange,
    showError,
    message,
    autoFocus,
    automationName,
    disabled,
  } = props;

  return (
    <FormItemWrapper data-automation="FormItemWrapper">
      <StyledInput
        state={showError ? 'error' : 'default'}
        message={showError ? message : ''}
        id={id}
        label={label}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        type="text"
        maxLength={maxLength}
        autoFocus={autoFocus}
        automationName={automationName}
        disabled={disabled}
      />
    </FormItemWrapper>
  );
};
