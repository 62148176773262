import React from 'react';
import { STRINGS } from 'config';
import { DebuggingFooter } from 'components/DebugFooter/style';

const { ENVIRONMENT: { IS_LOCAL, IS_DEV_OR_TEST_ENV } } = STRINGS;

const environmentName = () => {
  switch (true) {
    case IS_LOCAL:
      return 'Local';
    case IS_DEV_OR_TEST_ENV:
      return 'DTE/Test';
    default:
      return 'UNKNOWN!';
  }
};

export const DebugFooter = ({ showDebug, state, isAuthenticating }) => {
  if (!showDebug) { return null; }
  return (
    <DebuggingFooter>
      <h3>Debugging info</h3>
      <p>
        Environment: {environmentName()}   |
        isAuthenticating: {isAuthenticating?.toString()}   |
        isLoggedIn: {state?.isLoggedIn?.toString()}   |
        Current practice: {state?.selectedPractice?.prettyName?.toString() || 'none selected'}   |
        No of users: {state.usersInActivePracticeList?.total}   |
        Root URL: `${STRINGS.API.ROOT_URL}/auth/login`
        Admin for this practice: {state.selectedPracticeHasAdminRights?.toString()}
      </p>
    </DebuggingFooter>
  );
};
