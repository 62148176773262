import { theme as econsultTheme } from '@econsult/econsult-ui';

/** Font sizes */
const fontSizeSmall = '12px';
const fontSizePrimary = '14px';
const fontSizeMedium = '16px';
const fontSizeLarge = '18px';
const fontSizeXLarge = '22px';

/** Font weights */
const fontWeight400 = '400';
const fontWeight600 = '600';
const fontWeight700 = '700';

export default {
  bgModalContainer: '#E9E8ED',
  wrapperModalCloseButton: '#1E194B',
  dividerMatchingRight: '1px solid #D2D1DB',
  brandingPrimaryShadeFour: '#34305D',
  matchingContainerBorder: '1px solid #BCBAC9',
  dropdownPrimary: '#34305D',
  fontSizeSmall,
  fontSizePrimary,
  fontSizeMedium,
  fontSizeLarge,
  fontSizeXLarge,
  fontWeight400,
  fontWeight600,
  fontWeight700,
  ...econsultTheme,
};
