import styled from 'styled-components';
import { ColumnDiv } from 'components/Div';
import { Input } from '@econsult/econsult-ui/dist/components/Input';
import { InputElement, InputLabel, InputMessage, InputWrapper } from '@econsult/econsult-ui/dist/components/Input/InputStyles';

export const FormItemWrapper = styled(ColumnDiv)`
  position: relative;
  gap: 0.125rem;
`;

export const StyledInput = styled(Input)`
  && ${InputWrapper} {
    ${({ state }) => (state === 'default' ? '--input-color: red;' : '')}
  }

  & ${InputLabel} {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colorNeutralBlack};
  }

  & ${InputElement} {
    font-size: 0.75rem;
    line-height: 1.75;
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colorNeutralNeutral7};
    font-weight: 400;
    min-height: 47px;
    box-sizing: border-box;
  }

  & ${InputMessage} {
    font-size: 0.75rem;
  }
`;
