import { STRINGS } from 'config';

const {
  REDUCERS: {
    FEATURE_FLAGS: {
      GET_FEATURE_FLAGS_REQUEST,
      GET_FEATURE_FLAGS_SUCCESS,
    },
  },
} = STRINGS;

export const initialFeatureFlagsState = {};

export const featureFlagsReducer = (state, action) => {
  switch (action.type) {
    case GET_FEATURE_FLAGS_REQUEST:
      return initialFeatureFlagsState;
    case GET_FEATURE_FLAGS_SUCCESS:
      return { ...state, ...action.payload };
    default: {
      return state;
    }
  }
};
