import React from 'react';
import theme from 'styles/theme';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from 'styles/globalStyles';
import { Router } from 'routes';
import { StoreProvider, FeatureProvider } from 'store/configureStore';

const App = () => (
  <StoreProvider>
    <FeatureProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router />
      </ThemeProvider>
    </FeatureProvider>
  </StoreProvider>
);

export default App;
