import styled from 'styled-components';
import { Notification as SBNotification } from '@econsult/econsult-ui/dist/components/Notification';
import { TextFlow } from 'components/Typography';

export const MoreInfoWrapper = styled(TextFlow)`
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colorPrimaryPrimary10};
  color: ${({ theme }) => theme.colorPrimaryPrimary80};
`;

export const Notification = styled(SBNotification)`
  flex: 0;
  margin-bottom: 16px;
`;
