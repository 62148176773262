import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@econsult/econsult-ui/dist/components/Button';
import { Div, ColumnDiv } from 'components/Div';
import styled from 'styled-components';
import { ArrowLeftIcon } from '@econsult/econsult-ui/dist/assets';

const Body = styled(ColumnDiv)`
  flex: 1 1 auto;
  align-items: start;
  padding: 50px;
  background-color: ${({ theme }) => theme.colorNeutralBlack};
`;

const PolicyContainer = styled(Div)`
  padding: 40px;
  margin-top: 50px;
  min-width: 1000px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  font-family: ${({ theme }) => theme.fontFamilyPrimary};
  background-color: white;
`;

const PolicyHeader = styled.span`
  display: flex;
  align-items: center;
  gap: 32px;
  color: ${({ theme }) => theme.colorNeutralBrightWhite};
`;

export const CookiesPolicy = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.CookieFirst?.renderEmbeds();
  }, []);
  return (
    <Body>
      <PolicyHeader id="cookiefirst-heading">
        <Button type="button" state="secondary" size="large" onClick={() => navigate('/')} iconPosition="left" icon={<ArrowLeftIcon />}>Back</Button>
        <h1 style={{ display: 'inline' }}>Cookies policy</h1>
      </PolicyHeader>
      <PolicyContainer>
        <div id="cookiefirst-policy-page" />
      </PolicyContainer>
    </Body>
  );
};
