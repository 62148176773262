import styled from 'styled-components';
import { Form as UnstyledForm } from 'components/Form';
import { Notification as SBNotification } from '@econsult/econsult-ui/dist/components/Notification';
import { Label as DatepickerLabel } from '@econsult/econsult-ui/dist/components/Datepicker';
import { Div } from 'components/Div';

export const Notification = styled(SBNotification)`
  flex: 0;
`;

export const InputLabel = styled(DatepickerLabel)`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const InputRow = styled(Div)`
  gap: 50px;
  align-items: flex-end;
  button[type=submit] {
    margin-left: auto;
  }
`;

export const Form = styled(UnstyledForm)`
  margin-bottom: 0.8rem;
`;
