import React from 'react';
import { Option as MenuItem, Select as Menu, useSelect as useMenu } from '@econsult/econsult-ui/dist/components/Select';
import { Caret, MenuButton, MenuItems, UserActions } from '@econsult/econsult-ui/dist/components/TopBar/TopBarStyles';
import { redirectUserToLoginScreen } from 'store/action/auth';
import { STRINGS } from 'config';
import { useAppDispatch, useAppState } from 'store/configureStore';
import { useNavigate } from 'react-router-dom';

export const TopBarUserActions = () => {
  const dispatch = useAppDispatch();
  const { app: { user } } = useAppState();
  const navigate = useNavigate();
  const { toggleSelect: toggleUserSelect, selectContext: userSelectContext, isOptionsListOpen: isUserSelectOpen } = useMenu();

  const handleLogout = () => {
    dispatch({ type: STRINGS.REDUCERS.APP.RESET_ALL_STATE });

    localStorage.clear();
    redirectUserToLoginScreen();
  };

  return (
    <UserActions>
      <Menu closeOnClickOutside selectContext={userSelectContext} automationName="TopBarUserMenuDropdown">
        <MenuButton onClick={toggleUserSelect} isActive={isUserSelectOpen}>{user.currentUserEmail}<Caret /></MenuButton>
        <MenuItems>
          <MenuItem onClick={() => handleLogout()} data-automation="Logout_button">Log out</MenuItem>
          <MenuItem onClick={() => navigate('/cookiepolicy')} data-automation="cookie_policy_page_button">Cookie policy</MenuItem>
        </MenuItems>
      </Menu>
    </UserActions>
  );
};
