import React, { useState } from 'react';
import { AddUserButtonContainer, AddUserModalButton } from 'components/Table/tableHeader';
import { AddUserModal } from 'components/UserTable/AddUserModal';
import { STRINGS } from 'config';
import { CustomAnchoredToolTip } from 'components/Table';

const { RESET_USER_CREATION_STATUS } = STRINGS.REDUCERS.USER_MANAGEMENT;
export const AddNewUserButton = ({ dispatch, selectedPracticeHasAdminRights }) => {
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const handleCloseAddUserModal = () => {
    dispatch({ type: RESET_USER_CREATION_STATUS });
    setShowAddUserModal(false);
  };

  return (
    <AddUserButtonContainer>
      <AddUserModalButton
        data-automation="AddUserModalButton"
        onClick={() => setShowAddUserModal(true)}
        disabled={!selectedPracticeHasAdminRights}
        type="button"
      >
        Add new user
        {!selectedPracticeHasAdminRights && (
          <CustomAnchoredToolTip
            automationLabel="addNewUserDisabledTooltip"
            label="Contact your practice admin"
            align="bottom"
          />
        )}
      </AddUserModalButton>
      <AddUserModal
        isOpen={showAddUserModal}
        onClose={handleCloseAddUserModal}
      />
    </AddUserButtonContainer>
  );
};
