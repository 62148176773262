import _filter from 'lodash/filter';
import { STRINGS } from 'config';
import { insertSorted } from 'utils/lists';

const {
  REQUEST_STATUS: {
    IDLE,
    PENDING,
    SUCCEEDED,
    FAILED,
  },
  REDUCERS: {
    APP: { RESET_ALL_STATE, SET_PRACTICE },
    DEMAND_MANAGEMENT: {
      UPDATE_ADMIN_AVAILABILITY,
      API_GET_ADMIN_AVAILABILITY_REQUEST,
      API_GET_ADMIN_AVAILABILITY_SUCCESS,
      API_GET_ADMIN_AVAILABILITY_ERROR,
      API_POST_ADMIN_AVAILABILITY_REQUEST,
      API_POST_ADMIN_AVAILABILITY_SUCCESS,
      API_POST_ADMIN_AVAILABILITY_ERROR,
      GET_DEMAND_MANAGEMENT_ENABLED_REQUEST,
      GET_DEMAND_MANAGEMENT_ENABLED_SUCCESS,
      GET_DEMAND_MANAGEMENT_ENABLED_ERROR,
      GET_SCHEDULED_CLOSURES_REQUEST,
      GET_SCHEDULED_CLOSURES_SUCCESS,
      GET_SCHEDULED_CLOSURES_ERROR,
      DELETE_SCHEDULED_CLOSURES_REQUEST,
      DELETE_SCHEDULED_CLOSURES_ERROR,
    },
  },
} = STRINGS;

export const initialDemandManagementState = {
  getAdminAvailabilityRequestStatus: IDLE,
  updateAdminAvailabilityRequestStatus: IDLE,
  adminAvailability: null,
  demandManagementEnabledStatus: IDLE,
  demandManagementEnabled: false,
  scheduledClosures: [],
};

export const demandManagementReducer = (state, action) => {
  switch (action.type) {
    case SET_PRACTICE:
    case RESET_ALL_STATE: {
      return {
        ...initialDemandManagementState,
      };
    }
    case UPDATE_ADMIN_AVAILABILITY: {
      return {
        ...state,
        adminAvailability: {
          id: action.payload,
          label: STRINGS.FEATURE_VALUES.ADMIN_AVAILABILITY[action.payload].label,
        },
      };
    }
    case API_GET_ADMIN_AVAILABILITY_REQUEST: {
      return {
        ...state,
        getAdminAvailabilityRequestStatus: PENDING,
      };
    }
    case API_GET_ADMIN_AVAILABILITY_SUCCESS: {
      return {
        ...state,
        getAdminAvailabilityRequestStatus: SUCCEEDED,
        adminAvailability: action.payload ? {
          id: action.payload,
          label: STRINGS.FEATURE_VALUES.ADMIN_AVAILABILITY[action.payload].label,
        } : null,
      };
    }
    case API_GET_ADMIN_AVAILABILITY_ERROR: {
      return {
        ...state,
        getAdminAvailabilityRequestStatus: FAILED,
      };
    }
    case API_POST_ADMIN_AVAILABILITY_REQUEST: {
      return {
        ...state,
        updateAdminAvailabilityRequestStatus: PENDING,
      };
    }
    case API_POST_ADMIN_AVAILABILITY_SUCCESS: {
      return {
        ...state,
        updateAdminAvailabilityRequestStatus: SUCCEEDED,
        adminAvailability: {
          id: action.payload,
          label: STRINGS.FEATURE_VALUES.ADMIN_AVAILABILITY[action.payload].label,
        },
      };
    }
    case API_POST_ADMIN_AVAILABILITY_ERROR: {
      return {
        ...state,
        updateAdminAvailabilityRequestStatus: FAILED,
      };
    }
    case GET_DEMAND_MANAGEMENT_ENABLED_REQUEST:
      return { ...state, demandManagementEnabledStatus: PENDING, demandManagementEnabled: initialDemandManagementState.demandManagementEnabled };
    case GET_DEMAND_MANAGEMENT_ENABLED_SUCCESS:
      return { ...state, demandManagementEnabled: action.payload, demandManagementEnabledStatus: SUCCEEDED };
    case GET_DEMAND_MANAGEMENT_ENABLED_ERROR:
      return { ...state, demandManagementEnabledStatus: FAILED };
    case GET_SCHEDULED_CLOSURES_SUCCESS:
      return { ...state, scheduledClosures: action.payload };
    case GET_SCHEDULED_CLOSURES_REQUEST:
    case GET_SCHEDULED_CLOSURES_ERROR:
      return { ...state, scheduledClosures: initialDemandManagementState.scheduledClosures };
    case DELETE_SCHEDULED_CLOSURES_REQUEST:
      return { ...state, scheduledClosures: _filter(state.scheduledClosures, closure => closure.id !== action.payload) };
    case DELETE_SCHEDULED_CLOSURES_ERROR:
      return {
        ...state,
        scheduledClosures: insertSorted(state.scheduledClosures, action.payload, closure => new Date(closure.startDateTime)),
      };
    default: {
      return state;
    }
  }
};
