import React, { Suspense, useEffect, useState } from 'react';
import _map from 'lodash/map';
import {
  StyledColumnHeaderCell,
  TableContainer,
} from 'components/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import { useAppDispatch, useAppState } from 'store/configureStore';
import { STRINGS } from 'config';
import { TableHeader } from 'components/UserTable/TableHeader';
import { DeleteUserModal } from 'components/UserTable/DeleteUserModal';
import { apiGetUsersFromCurrentPractice } from 'store/action/userManagement';
import { UserTableRow } from './TableRow';

const {
  USER_MANAGEMENT: { RESET_USER_DELETE_STATUS },
} = STRINGS.REDUCERS;

export const UserTable = () => {
  const {
    app: { selectedPractice, selectedPracticeIsEhub, selectedPracticeHasAdminRights, user },
    userManagement: { usersInActivePracticeList },
  } = useAppState();
  const dispatch = useAppDispatch();
  const totalUsers = usersInActivePracticeList?.total;
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

  useEffect(() => {
    if (!selectedPracticeIsEhub) {
      if (selectedPractice) {
        apiGetUsersFromCurrentPractice(
          {
            practiceId: selectedPractice?.id,
            dispatch,
          },
        );
      }
    }
    return () => {};
  }, [selectedPractice, selectedPracticeIsEhub, dispatch]);

  const handleTableActionDeleteUser = email => {
    setShowDeleteUserModal(true);
    setSelectedEmail(email);
  };
  const handleOnCloseDeleteUserModal = () => {
    dispatch({ type: RESET_USER_DELETE_STATUS });
    setSelectedEmail(null);
    setShowDeleteUserModal(false);
  };

  return (
    <Suspense fallback={<p>Loading users...</p>}>
      <TableContainer id="SkipToUserListMainContent" data-automation="UserTableWrapper">
        {!selectedPracticeIsEhub && (
        <>
          <TableHeader
            totalUsers={totalUsers}
            dispatch={dispatch}
            selectedPracticeHasAdminRights={selectedPracticeHasAdminRights}
          />
          <TableContainer sx={{ border: 0, boxShadow: 0, mt: 2 }} component={Paper} data-automation="TableContainer">
            <Table aria-label="table">
              <TableHead>
                <TableRow data-automation="TableHeaderRow">
                  <StyledColumnHeaderCell data-automation="TableHeaderCellName">Name</StyledColumnHeaderCell>
                  <StyledColumnHeaderCell data-automation="TableHeaderCellEmail">Email</StyledColumnHeaderCell>
                  <StyledColumnHeaderCell data-automation="TableHeaderCellAdminRights">Admin rights</StyledColumnHeaderCell>
                  <StyledColumnHeaderCell sx={{ width: 50 }} data-automation="TableHeaderCellActions">Action</StyledColumnHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_map(usersInActivePracticeList.users, row => (
                  <UserTableRow
                    key={row.email}
                    row={row}
                    onDeleteUser={handleTableActionDeleteUser}
                    currentUserEmail={user.currentUserEmail}
                    selectedPracticeHasAdminRights={selectedPracticeHasAdminRights}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
        )}
      </TableContainer>
      <DeleteUserModal
        selectedEmail={selectedEmail}
        isOpen={showDeleteUserModal}
        onClose={handleOnCloseDeleteUserModal}
      />
    </Suspense>
  );
};
