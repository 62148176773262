import React from 'react';
import { STRINGS } from 'config';
import request from 'superagent';
import status from 'http-status';
import { toast } from '@econsult/econsult-ui/dist/components/Toast';
import { ErrorToast, SuccessToast } from 'components/Toast';
import { reportError } from 'utils/datadog';
import { retryCallback } from 'utils/apiRequests';

const {
  API: { CUSTOMISATION_URL, PRACTICE_URL },
  FEATURES: { ADMIN_AVAILABILITY, DEMAND_MANAGEMENT_ENABLED },
  FEATURE_VALUES,
  REDUCERS: {
    DEMAND_MANAGEMENT: {
      UPDATE_ADMIN_AVAILABILITY,
      API_GET_ADMIN_AVAILABILITY_REQUEST,
      API_GET_ADMIN_AVAILABILITY_SUCCESS,
      API_GET_ADMIN_AVAILABILITY_ERROR,
      API_POST_ADMIN_AVAILABILITY_REQUEST,
      API_POST_ADMIN_AVAILABILITY_SUCCESS,
      API_POST_ADMIN_AVAILABILITY_ERROR,
      SAVE_SCHEDULED_CLOSURE_REQUEST,
      SAVE_SCHEDULED_CLOSURE_SUCCESS,
      SAVE_SCHEDULED_CLOSURE_ERROR,
      GET_SCHEDULED_CLOSURES_REQUEST,
      GET_SCHEDULED_CLOSURES_SUCCESS,
      GET_SCHEDULED_CLOSURES_ERROR,
      DELETE_SCHEDULED_CLOSURES_REQUEST,
      DELETE_SCHEDULED_CLOSURES_SUCCESS,
      DELETE_SCHEDULED_CLOSURES_ERROR,
      GET_DEMAND_MANAGEMENT_ENABLED_REQUEST,
      GET_DEMAND_MANAGEMENT_ENABLED_SUCCESS,
      GET_DEMAND_MANAGEMENT_ENABLED_ERROR,
    },
  },
} = STRINGS;

export const updateAdminAvailability = payload => (
  ({ type: UPDATE_ADMIN_AVAILABILITY, payload })
);

export const apiGetAdminAvailability = practiceId => dispatch => {
  dispatch({ type: API_GET_ADMIN_AVAILABILITY_REQUEST });
  return request.get(`${CUSTOMISATION_URL}/feature/${ADMIN_AVAILABILITY}/${practiceId}`)
    .withCredentials()
    .set({ 'X-OWNER-ID': practiceId })
    .retry(3, retryCallback(dispatch, API_GET_ADMIN_AVAILABILITY_REQUEST))
    .then(response => {
      const isValidForFeature = Object.keys(FEATURE_VALUES.ADMIN_AVAILABILITY).includes(response.body.value);

      if (!isValidForFeature) {
        reportError(new Error(`invalid value for feature ${ADMIN_AVAILABILITY}`), {
          practiceId,
          feature: ADMIN_AVAILABILITY,
          featureValue: response.body.value,
        });

        return dispatch({ type: API_GET_ADMIN_AVAILABILITY_SUCCESS, payload: null });
      }

      return dispatch({ type: API_GET_ADMIN_AVAILABILITY_SUCCESS, payload: response.body.value });
    })
    .catch(error => {
      dispatch({ type: API_GET_ADMIN_AVAILABILITY_ERROR, payload: error });
    });
};

export const apiPostAdminAvailability = (practiceId, value) => dispatch => {
  if (!value) {
    toast.error(<ErrorToast message="There was an error. Please try again." />, { icon: false, autoClose: 12000 });

    reportError(new Error(`could not save value for feature ${ADMIN_AVAILABILITY}`), {
      practiceId,
      feature: ADMIN_AVAILABILITY,
      featureValue: value,
    });

    return dispatch({ type: API_POST_ADMIN_AVAILABILITY_ERROR });
  }

  dispatch({ type: API_POST_ADMIN_AVAILABILITY_REQUEST });

  return request.post(`${CUSTOMISATION_URL}/public/feature/value`)
    .withCredentials()
    .set({ 'X-OWNER-ID': practiceId })
    .send({
      featureId: ADMIN_AVAILABILITY,
      value,
    })
    .then(response => {
      dispatch({ type: API_POST_ADMIN_AVAILABILITY_SUCCESS, payload: response.body.value });
      toast(<SuccessToast message="Settings saved successfully" />);
    })
    .catch(error => {
      reportError(new Error(`could not save value for feature ${ADMIN_AVAILABILITY}`), {
        practiceId,
        feature: ADMIN_AVAILABILITY,
        featureValue: value,
        error,
      });

      dispatch({ type: API_POST_ADMIN_AVAILABILITY_ERROR, payload: error });
      toast.error(<ErrorToast message="There was an error. Please try again." />, { icon: false, autoClose: 12000 });
    });
};

export const saveScheduledClosure = (practiceId, closure) => dispatch => {
  dispatch({ type: SAVE_SCHEDULED_CLOSURE_REQUEST });
  return request
    .post(`${PRACTICE_URL}/${practiceId}/closure`)
    .withCredentials()
    .retry(3, retryCallback(dispatch, SAVE_SCHEDULED_CLOSURE_REQUEST))
    .send(closure)
    .then(response => {
      dispatch({ type: SAVE_SCHEDULED_CLOSURE_SUCCESS, payload: response.body });
      toast(<SuccessToast message="Scheduled closure saved successfully" />);
      return true;
    })
    .catch(error => {
      dispatch({ type: SAVE_SCHEDULED_CLOSURE_ERROR, payload: error });
      const toastText = `Unable to save scheduled closure. Please ${error.status === 401 ? 'refresh the page and ' : ''}try again.`;
      toast.error(<ErrorToast message={toastText} />, {
        icon: false,
        autoClose: 12000,
      });
      return false;
    });
};

export const getScheduledClosures = practiceId => dispatch => {
  dispatch({ type: GET_SCHEDULED_CLOSURES_REQUEST });
  return request
    .get(`${PRACTICE_URL}/${practiceId}/closures`)
    .withCredentials()
    .retry(3, retryCallback(dispatch, GET_SCHEDULED_CLOSURES_REQUEST))
    .then(response => dispatch({
      type: GET_SCHEDULED_CLOSURES_SUCCESS,
      payload: response.body.sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime)),
    }))
    .catch(error => {
      dispatch({ type: GET_SCHEDULED_CLOSURES_ERROR, payload: error });
      toast.error(<ErrorToast message="Unable to get scheduled closures" />, {
        icon: false,
        autoClose: 12000,
      });
    });
};

export const deleteScheduledClosures = (practiceId, closure) => dispatch => {
  dispatch({ type: DELETE_SCHEDULED_CLOSURES_REQUEST, payload: closure.id });
  const successToastText = 'Scheduled closure has been successfully deleted.';
  return request
    .delete(`${PRACTICE_URL}/${practiceId}/closure/${closure.id}`)
    .withCredentials()
    .then(() => {
      dispatch({ type: DELETE_SCHEDULED_CLOSURES_SUCCESS });
      toast(<SuccessToast message={successToastText} />);
    })
    .catch(error => {
      if (error.status === status.NOT_FOUND) {
        dispatch({ type: DELETE_SCHEDULED_CLOSURES_SUCCESS });
        return toast(<SuccessToast message={successToastText} />);
      }
      const errorToastText = error.status === status.UNAUTHORIZED
        ? 'Unable to delete scheduled closure. Please refresh the page and try again.'
        : 'This scheduled closure failed to delete. Please try again or contact us via live chat if the issue persists.';
      dispatch({ type: DELETE_SCHEDULED_CLOSURES_ERROR, payload: closure });
      return toast.error(
        <ErrorToast message={errorToastText} />,
        { icon: false, autoClose: 12000 },
      );
    })
    .finally(() => dispatch(getScheduledClosures(practiceId)));
};

export const getDemandManagementEnabled = practiceId => dispatch => {
  dispatch({ type: GET_DEMAND_MANAGEMENT_ENABLED_REQUEST });
  return request
    .get(`${CUSTOMISATION_URL}/feature/${DEMAND_MANAGEMENT_ENABLED}/${practiceId}`)
    .set({ 'X-OWNER-ID': practiceId })
    .retry(3, retryCallback(dispatch, GET_DEMAND_MANAGEMENT_ENABLED_REQUEST))
    .then(response => dispatch({ type: GET_DEMAND_MANAGEMENT_ENABLED_SUCCESS, payload: response.body.value }))
    .catch(error => dispatch({ type: GET_DEMAND_MANAGEMENT_ENABLED_ERROR, payload: error }));
};
