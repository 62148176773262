import styled, { css } from 'styled-components';
import { ParagraphXSmallRegular } from 'components/Typography';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const Legend = styled(ParagraphXSmallRegular).attrs({ as: 'legend' })`
  padding: 0;
`;

const stylesToFixFieldsetGapLayoutBug = css`
  && > * {
    margin: 0;
  }

  && > * + * {
    margin-top: 0.8rem;
  }
`;

export const FieldSet = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
  ${stylesToFixFieldsetGapLayoutBug};
`;
